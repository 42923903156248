import React from 'react';



import Logo from '../UI/logo1old.jpg';

import footerimg from '../UI/footerimg.JPEG XR';

import '../Styling/Footer.css';


const Footer = () => {



    return (
        <div style={{ backgroundColor: "#292b2c",color:"white" }}>
            <br />

            <br />
            <br />


            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img className="logoFooter" src={Logo} alt="RJIS" />
                    </div>
                    <div className="col-md-6">
                        <div id="footerIcons" style={{ display:"flex" }}>
                            <a className="btn btn-primary btn-lg btn-floating" style={{ backgroundColor: '#3b5998' }} href="#!" role="button"><i className="fab fa-facebook-f" /></a>
                            {/* Twitter */}
                            <a className="btn btn-primary btn-lg btn-floating" style={{ backgroundColor: '#55acee' }} href="#!" role="button"><i className="fab fa-twitter" /></a>
                            {/* Instagram */}
                            <a className="btn btn-primary btn-lg btn-floating" style={{ backgroundColor: '#ac2bac' }} href="#!" role="button"><i className="fab fa-instagram" /></a>
                            {/* Linkedin */}
                            <a className="btn btn-primary btn-lg btn-floating" style={{ backgroundColor: '#0082ca' }} href="#!" role="button"><i className="fab fa-linkedin-in" /></a>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <hr/>
                <br/>
                <div className="row">
                    {/* <div className="col-md-3">
                        <img id="imageFooterRow" src={footerimg} alt=""/>
                    </div> */}
                    <div className="col-md-6 col-sm-6">
                        <ul>
                            <li style={{fontWeight:"bolder"}}>HEAD OFFICE</li>
                            <li>RJ Inter Lock Sytems</li>
                            <li>House No. 3-147-31</li>
                            <li>Nizampet, Kukatpally,Hyderabad-50009</li>
                            <li>Telangana, India</li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <ul>
                            <li style={{fontWeight:"bolder"}}>CONTACT</li>
                            <li>Tel: +91-7036255107</li>
                            <li>Tel: +91-7286806411</li>
                            <li><a  href="https://wa.me/7036255107?text=Hi" style={{color:"white", display: "table-cell"}} target="_blank"><li>Whatsapp</li></a></li>
                            <li>E-mail: rjinterlocksystems@gmail.com</li>
                        </ul>
                    </div>
                    {/* <div className="col-md-3">
                        <ul>
                            <li style={{fontWeight:"bolder"}}>SERVICE</li>
                            <li>TOLL 1.800.565.1851</li>
                            <li>PHONE 416.743.3667</li>
                            <li>Fax: (866) 831-0737</li>
                            <li>CUSTOMER SERVICE ext 226 or 230</li>
                        </ul>
                    </div> */}
                </div>
      
                <hr/>
                <br/>
                <div className="row">
                    <h6 style={{fontWeight:"lighter"}}>© 2022 RJIS. All rights reserved.</h6>
                </div>
            </div>

            <br />
            <br />

        </div>
    )
}
export default Footer;