import React from 'react';



import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/Products.css';



import cwf from '../UI/cwf.JPEG XR'
import aw from '../UI/aw.JPEG XR'
import ad from '../UI/ad.JPEG XR'
import asf from '../UI/asf.JPEG XR'
import agr from '../UI/agr.JPEG XR'
import ip from '../UI/ip.JPEG XR'
import pbdigital from '../UI/20211112_092825.jpg'

const Products = () => {



    React.useEffect(() => {


    });

    return (
        <div>
            <br />
            
            <h1 className="text-center text-uppercase">
                {/* <ScrollAnimation animateIn='flipInY' */}
                    {/* animateOut='flipOutY'> */}
                    Our Products
                {/* </ScrollAnimation> */}
            </h1>
            <h5 style={{ fontWeight: "lighter", textAlign: "center" }}>
                <ScrollAnimation animateIn='fadeIn' delay={400}>
                    RJIS offers a wide range of Pharma Tech products and services.

                    All our products comply with ISO and ISI standand and Regulations.
                </ScrollAnimation>
            </h5>
            <br />
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/ANDROID/Default/2020/8/OE/FF/LG/14672129/product-jpeg-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Door Interlocking System
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Usage/Application: Pharmaceutical Industry</li>
                                        <li>Voltgae: 12V</li>
                                        <li>LCD Version Also available</li>
                                        <li>Brand: RJIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/DY/HJ/MY-14672129/dynamic-pass-box-controller-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Cleanroom Pass Box
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Usage/Application: Pharmaceutical Industry</li>
                                        <li>Size: 200*220*40</li>
                                        <li>Material: Stainless Steel</li>
                                        <li>Brand: RJIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/LA/XV/MY-14672129/air-shower-controller-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Air Shower Controller
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>IP Rating: IP68</li>
                                        <li>Power: 8.7KW</li>
                                        <li>Material: Stainless Steel, Aluminium</li>
                                        <li>Temperature: 41-48 Degree Celsius</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/ZJ/YC/FR/ANDROID-14672129/img-20190814-wa0024-jpg-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Pass Box Door Interlocking
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Power/Voltage: 12 v</li>
                                        <li>Feature	Rust Proof</li>
                                        <li>Voltage: 230v</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/ANDROID/Default/2022/5/VX/KU/FJ/14672129/prod-20220512-1741046233272281549452700-jpg-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Pharma Door Interlocking
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Material: Stainless Steel</li>
                                        <li>2 door interlock ,3 door interlock,4 door interlock</li>
                                        <li>5 door interlock ,6 door interlock ,7door interlock</li>
                                        <li>Exclusive clean room</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src="https://5.imimg.com/data5/ANDROID/Default/2022/5/FK/LS/YL/14672129/product-jpeg-500x500.jpg" alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Flp Pass Box Controller
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Cleanroom door interlock FLP</li>
                                        <li>FLP 2 door interlock</li>
                                        <li>Complete Fire shield</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                <div className="col-md-4">
                        <div className="containerInside">
                            <img className="image" src={pbdigital} alt="pic" />
                            <div className="imageOverlay imageOverlayBlur">
                                <div className="image_title">
                                    Cleanroom Pass Box
                                </div>
                                <div className="imageDisc">
                                    <ul>
                                        <li>Usage/Application: Pharmaceutical Industry</li>
                                        <li>Size: 200*220*40</li>
                                        <li>Material: Stainless Steel</li>
                                        <li>Brand: RJIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                  
                </div>
            </div>
        </div>
    )
}
export default Products;
