import React from 'react';

import Header from './Header';

import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/AboutUsPage.css';


import safety from '../UI/safety.JPEG XR'

import Contact from './Contact';

import Footer from './Footer';

const Health = () => {



    React.useEffect(() => {


    });

    return (
        <div>
            <div className="container">

                <div className="fixed-top">
                    <Header />
                </div>
            </div>
            <br />
            <br /><br />
            
            <div className="animatedArrow">
                <a href="#aboutushead" style={{borderRadius:"50%", border: "0px solid #ADD8E6", fontSize: 50, backgroundColor: "transparent",  }} className="btn-floating btn-large red">
                    <i className="fa fa-arrow-down" />
                </a>
            </div>

            <section className="showcase2">
                <div className="image-container">
                    <img src="https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="About Us" />
                </div>
                <div className="content2">
                    {/* <h1>{TEXTS[index]}</h1> */}
                    <h1 id="aboutushead">
                        <ScrollAnimation animateIn="fadeIn" delay={200}>
                            BLOG
                        </ScrollAnimation>
                    </h1>
                </div>
            </section>
            <div className="container">
                <div>
                    <br />
                    <br />
                    <h1 style={{ textTransform: "uppercase", textAlign: "center" }}>
                        <ScrollAnimation delay={400}
                            animateIn='flipInX'
                            animateOut='flipOutY'
                        >
                            RJIS BLOG
                         </ScrollAnimation>
                    </h1>
                    <br />
                    {/* <h3 style={{ textTransform: "uppercase", fontWeight: "lighter" }}>
                        <ScrollAnimation animateIn="bounceInLeft" delay={200}>
                            We make it easy for you to find the best windows and doors <br />
            to reflect your style and taste
                </ScrollAnimation>

                    </h3> */}
                    <h5 style={{ fontWeight: "lighter", textAlign: "center", lineHeight: "35px" }}>
                        <ScrollAnimation animateIn="fadeIn" delay={300}>
                            RJIS will provide all the information of latest technology in this blog <br />
Comming Soon
                </ScrollAnimation>
                    </h5>
                </div>
                <Contact />
            </div>
            <Footer />
        </div>
    )
}
export default Health;