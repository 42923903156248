import React from 'react';




import Header from './Header';


import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/AboutUsPage.css';


import aboutimg from '../UI/about.JPEG XR'

import Contact from './Contact';

import Footer from './Footer';

const AboutUsPage = () => {



    React.useEffect(() => {


    });

    return (
        <div>
            <div className="container">
                <div className="fixed-top">
                    <Header />
                </div>
            </div>

            <div className="animatedArrow">
                <a href="#bottom" style={{borderRadius:"50%", border: "0px solid #ADD8E6", fontSize: 50, backgroundColor: "transparent", color :"white" }} className="btn-floating btn-large red">
                    <i className="fa fa-arrow-down" />
                </a>
            </div>

            <section className="showcase2">
                <div className="image-container">
                    <img src="https://images.unsplash.com/photo-1581092921461-eab62e97a780?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="About Us" />
                </div>
                <div className="content2">
                    {/* <h1>{TEXTS[index]}</h1> */}
                    <h1 id="aboutushead">
                        <ScrollAnimation animateIn="fadeIn" delay={200}>
                            About Us
                        </ScrollAnimation>
                    </h1>
                </div>
            </section>
            <div className="container">
                <div>
                    <br />
                    <br />
                    <h1 style={{ textTransform: "uppercase", textAlign: "center" }}>
                        <ScrollAnimation delay={400}
                            animateIn='flipInX'
                            animateOut='flipOutY'
                        >
                             We are high-tech interlocking solutions for all your needs and 
            also deliver all Phrama equiments.
                         </ScrollAnimation>
                    </h1>
                    <br />
                    <span id="bottom"></span>
                    <h5 style={{ fontWeight: "lighter", textAlign: "center", lineHeight: "35px" }}>
                        <ScrollAnimation animateIn="fadeIn" delay={300}>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  RJIS is a leading Phrama tech solutions provider offering a wide
                    range of Phrama tech products and services.We specialize in
                    design, fabrication and installation of Passbox, Door interlocking, Air shower,  
                    Bio Safety controller and Laminar Air flow machines for all Pharama and external applications.
                    We are committed towards quality and customer service. <br /> <br /> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; Quality is not just another goal, it is our basic
                    strategy for survival and future as it’s our journey to the Total Quality Management.
                    It is our long-term commitment to continously improve our services, provide a safe work equiments for our clients, and efficiently manage our business process and supplier selection retention. It is our goal to posture
                    our company for market expansion, thereby providing improved best service and quality for all.
                </ScrollAnimation>
                    </h5>
                </div>
                <Contact />
            </div>
            <Footer />
         
        </div>
    )
}
export default AboutUsPage;