import React from 'react';

import '../Styling/SlideShow.css';

import video from '../UI/SlideVideo.mp4';

import { Link } from "react-router-dom"



const SlideShow = () => {

    return (
        <div>
            <section className="showcase">
                <div className="video-container">
                    <video autoPlay loop muted controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <h3 className="animateIt"style={{ color : "white" }}>Door interlocking, Passbox, Clean room equipments and Air shower are our speciality.</h3>
                    <h4 className="animateIt" style={{ fontWeight: "lighter",color : "white" }}>Pharma Tech is our passion.</h4>
                    <br/>
                    <Link to="/about" className="btns" style={{ color : "white" }}>Read More</Link>
                </div>
            </section>

        </div>
    )
}
export default SlideShow;