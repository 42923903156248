import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/AboutUsPage.css';


const Services = () => {


    return (
        <div>
            <div className="container">
                <div>
                    <br />
                    <hr />
                    <h1 className="text-center text-uppercase">
                        <ScrollAnimation animateIn='flipInY'
                            animateOut='flipOutY' initiallyVisible={true}>
                            Our Services
                    </ScrollAnimation>
                    </h1>
                    {/* <h5 style={{ fontWeight: "lighter", textAlign: "center" }}>
                        <ScrollAnimation animateIn='fadeIn' delay={400}>
                            AlumTech Glass and Aluminium offers a wide range of aluminium products and services.

                            All our products comply with Dubai Green Building Regulations, Dubai Civil Defense and Abu Dhabi Estidama Regulations.
                    </ScrollAnimation>
                    </h5> */}
                    <br />

                    {/* Here will be all services */}
                    <div>
                        {/* Tabs navs */}
                        <ul className="nav nav-tabs nav-justified mb-3" id="ex1" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="ex3-tab-1" data-mdb-toggle="tab" href="#ex3-tabs-1" role="tab" aria-controls="ex3-tabs-1" aria-selected="true">IDEATION AND DESIGN</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="ex3-tab-2" data-mdb-toggle="tab" href="#ex3-tabs-2" role="tab" aria-controls="ex3-tabs-2" aria-selected="false">FABRICATION AND ENGINEERING</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="ex3-tab-3" data-mdb-toggle="tab" href="#ex3-tabs-3" role="tab" aria-controls="ex3-tabs-3" aria-selected="false">LOGISTICS AND INSTALLATION</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="ex3-tab-4" data-mdb-toggle="tab" href="#ex3-tabs-4" role="tab" aria-controls="ex3-tabs-4" aria-selected="false">INNOVATION</a>
                            </li>
                        </ul>
                        {/* Tabs navs */}
                        {/* Tabs content */}
                        <div className="tab-content" id="ex2-content">
                            <div className="tab-pane fade show active" id="ex3-tabs-1" role="tabpanel" aria-labelledby="ex3-tab-1">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-7">
                                            <h1>
                                                <ScrollAnimation animateIn='bounceInRight' delay={400} initiallyVisible={true}>
                                                IDEATION AND DESIGN 
                                                </ScrollAnimation>
                                            </h1>
                                            <h5 style={{ fontWeight: "lighter" }}>
                                                <ScrollAnimation animateIn="fadeIn" initiallyVisible={true}>
                                                <br />
                                                   RJIS offer a complete range of engineering services to our client include the
                                                initial design that offering most economical solutions and meet or exceed the product standands. <br /> <br />
                                                With our professional and expert designers, we provide to our client a complete detailed Factory drawings,
                                                Machine layout drawing and Space Management.
                                                </ScrollAnimation>
                                            </h5>
                                        </div>
                                        <div className="col-md-5">
                                            <img src="https://images.unsplash.com/photo-1580894723150-0ff6e9b907ea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" width="100%" height="300" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ex3-tabs-2" role="tabpanel" aria-labelledby="ex3-tab-2">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-7">
                                            <h1>
                                                <ScrollAnimation animateIn='bounceInRight' delay={400} initiallyVisible={true}>
                                                    FABRICATION AND ENGINEERING
                                                </ScrollAnimation>
                                            </h1>
                                            <h5 style={{ fontWeight: "lighter" }}>
                                                <ScrollAnimation animateIn="fadeIn" initiallyVisible={true}>
                                                <br />
                                                    We are the most leading manufacture in India that provide best quality for different type
                                                    of Pharma product.
                                                    Also our intimate relationship with our suppliers ensures we can always deliver quality and on time
                                                    deliver to our clients.
                                                </ScrollAnimation>
                                            </h5>
                                        </div>
                                        <div className="col-md-5">
                                            <img src="https://images.unsplash.com/photo-1563770660941-20978e870e26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGVsZWN0cm9uaWNzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" width="100%" height="300" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ex3-tabs-3" role="tabpanel" aria-labelledby="ex3-tab-3">

                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-7">
                                            <h1>
                                                <ScrollAnimation animateIn='bounceInRight' delay={400} initiallyVisible={true}>
                                                    LOGISTICS AND INSTALLATION
                                              </ScrollAnimation>
                                            </h1>
                                            <h5 style={{ fontWeight: "lighter" }}>
                                                <ScrollAnimation animateIn="fadeIn" initiallyVisible={true}>
                                                <br />
                                                    RJIS using premier installers, this ensures all interlocking, Passbox, Air showers and
                                                    other related product installed by an experienced team of installers that is following all the safety
                                                    guidelines and all are certified to work with High Standands, using different types of installation equipment.F
                                              </ScrollAnimation>
                                            </h5>
                                        </div>
                                        <div className="col-md-5">
                                            <img src="https://images.unsplash.com/photo-1621905251918-48416bd8575a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bWFjaGluZSUyMGluc3RhbGxhdGlvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" width="100%" height="300" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ex3-tabs-4" role="tabpanel" aria-labelledby="ex3-tab-4">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-7">
                                            <h1>
                                                <ScrollAnimation animateIn='bounceInRight' delay={400} initiallyVisible={true}>
                                                    INNOVATION
                                                </ScrollAnimation>
                                            </h1>
                                            <h5 style={{ fontWeight: "lighter" }}>
                                                <ScrollAnimation animateIn="fadeIn" initiallyVisible={true}>
                                                    <br />
                                                    Innovation is heart of RJIS and We have a separate R&D team specializes in delivering improved product to meet any requirement for Custom needs
                                                    and emergency project like Dedicated Innovation, any replacements and Specialized services.
                                                    <br />
                                                </ScrollAnimation>
                                            </h5>
                                        </div>
                                        <div className="col-md-5">
                                            <img src="https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGlubm92YXRpb258ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" width="100%" height="300" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tabs content */}
                    </div>
                    {/* Here will be all services */}

                </div>
                <br />

            </div>
        </div>
    )
}
export default Services;