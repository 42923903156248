import React from 'react';


import Header from './Header';

import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/AboutUsPage.css';

import services from '../UI/services.JPEG XR'

import dc from '../UI/dc.JPEG XR'
import fe from '../UI/fe.JPEG XR'
import li from '../UI/li.JPEG XR'
import so from '../UI/so.JPEG XR'

import Contact from './Contact';

import Footer from './Footer';
import Services from "./Services"

const ServicesPage = () => {


    return (
        <div>
            <div className="container">
                <div className="fixed-top">
                    <Header />
                </div>
            </div>

            <div className="animatedArrow">
                <a href="#aboutushead" style={{ borderRadius: "50%", border: "0px solid #ADD8E6", fontSize: 50, backgroundColor: "transparent",color :"white" }} className="btn-floating btn-large red">
                    <i className="fa fa-arrow-down" />
                </a>
            </div>

            <section className="showcase2">
                <div className="image-container">
                    <img src="https://img.freepik.com/premium-photo/engineer-checks-operation-electrical-control-equipment-maintenance-concept_539854-1437.jpg" alt="Services" />
                </div>
                <div className="content2">
                    {/* <h1>{TEXTS[index]}</h1> */}
                    <h1 id="aboutushead">
                        <ScrollAnimation animateIn="fadeIn" delay={200}>
                            OUR SERVICES
                        </ScrollAnimation>
                    </h1>
                </div>
            </section>

            <Services/>
            <Footer />
            {/* <div id="bottom"></div> */}
        </div>
    )
}
export default ServicesPage;