import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/database'
//import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCFxspY1x0jrB7EXjFQxC-jv-yfAMmZQdM",
    authDomain: "rjis-web.firebaseapp.com",
    projectId: "rjis-web",
    storageBucket: "rjis-web.appspot.com",
    messagingSenderId: "357649670208",
    appId: "1:357649670208:web:4f8d1c11b03540b320bfa5",
    measurementId: "G-6W11KZ7TTF"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const storage = firebase.storage();

export {
    storage,
    firebase as default
}
