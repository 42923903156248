import React from 'react';

import { Link } from "react-router-dom";

import proudlyindian from "../UI/proudlyindian.png"

import ScrollAnimation from 'react-animate-on-scroll';

import '../Styling/About.css';


const About = () => {



    React.useEffect(() => {


    });

    return (
        <div>
            <br />
            <hr />
            <h1 style={{ textTransform: "uppercase" }}>
                <ScrollAnimation delay={400}
                    animateIn='flipInX'
                    animateOut='flipOutY'
                >
                    RJIS
            </ScrollAnimation>
            </h1>
           
            <h3 style={{ textTransform: "uppercase", fontWeight: "lighter" }}>
                <ScrollAnimation animateIn="bounceInLeft" delay={200}>
                    We are high-tech interlocking solutions for all your needs and  <br />
            also deliver all Phrama equiments.
                </ScrollAnimation>
            </h3>
            <br />
            <h5 style={{ fontWeight: "lighter" }}>
                <ScrollAnimation animateIn="fadeIn" delay={300}>
                    &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;  RJIS is a leading Phrama tech solutions provider offering a wide
                    range of Phrama tech products and services.We specialize in
                    design, fabrication and installation of Passbox, Door interlocking, Air shower,  
                    Bio Safety controller and Laminar Air flow machines for all Pharama and external applications.
                    We are committed towards quality and customer service. <br /> <br /> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; Quality is not just another goal, it is our basic
                    strategy for survival and future as it’s our journey to the Total Quality Management.
                    It is our long-term commitment to continously improve our services, provide a safe work equiments for our clients, and efficiently manage our business process and supplier selection retention. It is our goal to posture
                    our company for market expansion, thereby providing improved best service and quality for all.
                </ScrollAnimation>
            </h5>
            <br />
            <div style={{ textAlign: "center" }}>
                <Link id="aboutuspagebtn" to="/about" className="btn">Learn More</Link>
            </div>

            <br />

            <div className="text-center">
                <img src={proudlyindian} width="250" alt="proudlycanadian" />

                <br />

                <br/>

                <h3 style={{ textTransform: "uppercase", fontWeight: "lighter" }}>
                    <ScrollAnimation animateIn="bounceInLeft" delay={200}>
                        Proudly Indian
                </ScrollAnimation>
                </h3>

            </div>



            <br />

        </div>
    )
}
export default About;